<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="my-4 text-4xl font-semibold">Email Templates</h2>
    <div class="pb-2 flex items-center justify-between text-gray-600 border-b">
      <div>
        <span>
          <span class="text-primary"> {{ items.length }} </span>
          templates
        </span>
      </div>
      <router-link
        class="w-100 flex flex-row items-center justify-center p-2 text-white font-semibold bg-primary rounded cursor-pointer"
        v-if="hasAccess('email-templates', 'add')"
        :to="{ name: 'admin-add-email-template' }"
      >
        Add email template
      </router-link>
    </div>

    <!-- Email Templates Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated" style="padding: 10px"
      :sticky-header="true"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Email Templates Table Content Header -->
      <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th
            class="py-3 px-6 text-center"
            v-if="hasAccess('email-templates', 'edit')"
          >
            Edit
          </th>
          <th
            @click="sort('id')"
            class="py-3 px-6 text-center cursor-pointer"
          >
            ID
          </th>
          <th
            @click="sort('created')"
            class="py-3 px-6 text-center cursor-pointer"
          >
            Date
          </th>
        </tr>
      </thead>
      <!-- Email Templates Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
        <tr
          v-for="item in sortedItems"
          :key="item.id"
          class="border-b border-gray-200 hover:bg-gray-100"
        >
          <td
            class="py-3 px-3 text-center"
            v-if="hasAccess('email-templates', 'edit')"
          >
            <div class="flex item-center justify-center">
              <router-link
                :to="{
                  name: 'admin-update-email-template',
                  params: { id: item.id },
                }"
                class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
              >
                <EditIcon />
              </router-link>
            </div>
          </td>
          <td class="py-3 px-3 text-center">
            {{ item.id }}
          </td>
          <td class="py-3 px-3 text-center">
            {{ item.created.toDate() | moment("calendar") }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Email Templates Table Content Pagination -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.items.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>

  </div>
</template>

<script>
import { emailTemplateCollection } from "../../firebase.js";
import permissionsMixin from "../../mixins/permissions";

import EditIcon from "vue-material-design-icons/PencilOutline";

export default {
  name: "AdminEmailTemplatesList",
  components: {
    EditIcon,
  },
  mixins: [permissionsMixin],
  data: () => ({
    items: [],
    currentSort:'name',
    currentSortDir:'asc',
    pageSize:10,
    currentPage:1,
  }),
  methods: {
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.items.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    getList() {
      emailTemplateCollection.orderBy("created").onSnapshot((snap) => {
        let items = [];
        snap.forEach((doc) => {
          items.push({
            created: doc.data().created,
            id: doc.id,
          });
        });
        this.items = items;
      });
    },
  },
  created() {
    this.getList();
  },
  computed: {
    sortedItems:function() {
      let items = this.items
      return items.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}
</style>
